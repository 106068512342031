wo$(function () {
    wo$('#site-search2').click(function () {
        var baseUrl = wo$(this).attr('data-url');
        var searchQuery = wo$('#site-search-query2').val();
        var url = WhistleOut.updateQueryStringParameter(baseUrl, 'q', encodeURIComponent(searchQuery));
        location.href = url;
    });

    wo$('#site-search-query2').keydown(function (event) {
        if (event.which === 13) {
            wo$('#site-search2').click();
            return false;
        }
    });
});
